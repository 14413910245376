import { type BreakpointObserver } from '@angular/cdk/layout';
import { type DeviceStore, TailwindBasedBreakpoints, TailwindBreakpointAliases } from '@sds/shared';
import { distinctUntilChanged, map } from 'rxjs/operators';

export function deviceMediaObserverInitializer(deviceStore: DeviceStore, breakpointObserver: BreakpointObserver) {
  return () => {
    return new Promise<void>(resolve => {
      breakpointObserver
        .observe(Object.values(TailwindBasedBreakpoints))
        .pipe(
          map(result => {
            const activeBreakpoint = Object.keys(result.breakpoints).find(bp => result.breakpoints[bp]);
            const isTouch: boolean = window.matchMedia('(pointer: coarse)').matches;
            return {
              activeBreakpoint: usingObjectEntries(
                TailwindBasedBreakpoints,
                activeBreakpoint
              ) as TailwindBreakpointAliases,
              isTouch: isTouch
            };
          }),
          distinctUntilChanged((a, b) => a.activeBreakpoint === b.activeBreakpoint && a.isTouch === b.isTouch)
        )
        .subscribe(device => {
          deviceStore.setDevice(device.activeBreakpoint, device.isTouch);
          resolve();
        });
    });
  };
}

function usingObjectEntries(obj: typeof TailwindBasedBreakpoints, value?: string) {
  return !value ? TailwindBreakpointAliases.default : Object.entries(obj).find(([key, val]) => val === value)![0];
}
